import axios from 'axios'

export class Api {
  constructor(config) {
    axios.defaults.headers.common['X-CSRFToken'] = config.csrfToken
  }

  requestElements = (handler, pageId) => {
    axios
      .get(config.urlElementGet, {
        params: {
          page_id: pageId,
        },
      })
      .then(function(response) {
        handler(response.data)
      })
  }

  requestActivities = (handler, pageId) => {
    axios
      .get(config.urlActivityGet, {
        params: {
          page_id: pageId,
        },
      })
      .then(function(response) {
        handler(response.data)
      })
  }

  toggleShareSubmission = (handler, submissionId) => {
    axios
      .post(config.urlShareSubmission, {
        submission_id: submissionId,
      })
      .then(function(response) {
        handler({
          submissionId: response.data['submission_id'],
          shared: !!response.data['shared'],
        })
      })
  }

  submit = (handler, activity, itemId, response) => {
    axios
      .post(config.urlSubmissionPost, {
        response,
        item_id: itemId,
        step_id: activity.nextStepId(itemId),
        submission_id: false,
      })
      .then(function(response) {
        handler({
          submissionId: response.data['submission_id'],
          nextStepId: response.data['next_step_id'],
          sequence: response.data['sequence'],
          message: response.data['message'],
        })
      })
  }
}
