import $ from 'jquery'
import bootstrap from 'bootstrap'
import popper from 'popper.js'
import { Controller } from './controller'
import { library } from './library'

$(document).ready(function() {
  if (config.page === 'page') window.app = new Controller()
  else if (config.page === 'library') library()
})
