import axios from 'axios'
import $ from 'jquery'

export function library() {
  axios.defaults.headers.common['X-CSRFToken'] = config.csrfToken
  const body = $('body')
  body.on('click', 'span.add-to-shelf', function(event) {
    axios
      .post(config.urlAddToShelf, {
        textbook_id: event.target.dataset['id'],
        add: true,
      })
      .then(function(response) {
        const target = $(event.target)
        target.removeClass('add-to-shelf')
        target.addClass('remove-from-shelf')
        target.text('remove')
      })
  })
  body.on('click', 'span.remove-from-shelf', function(event) {
    axios
      .post(config.urlAddToShelf, {
        textbook_id: event.target.dataset['id'],
        add: false,
      })
      .then(function(response) {
        const target = $(event.target)
        target.addClass('add-to-shelf')
        target.removeClass('remove-from-shelf')
        target.text('add')
      })
  })
}
