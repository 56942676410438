import $ from 'jquery'

export class View {
  constructor(elements) {
    this.textLayer = $('div#page-text')
    this.elements = this._createTextLayer(elements)
    this.popover = this._createPopover()
    this.modal = this._createModal() // TODO: offer ability to render an activity full screen instead, eg, blog
    this._initLocalListeners()
  }

  _initLocalListeners = () => {
    // clear popover whenever user clicks
    $('html').on('mouseup', function(event) {
      $('.popover').each(function() {
        $(this).popover('hide')
      })
    })
    $('input.high-contrast').on('click', event => {
      if (event.target.checked) {
        this.textLayer[0].style.cssText =
          'background-color: white; color: black;'
        // BUG: Only seems to find the top checkbox
        $('input.high-contrast').checked = true
      } else {
        this.textLayer[0].style.cssText =
          'background-color: transparent; color: transparent;'
      }
      $('input.high-contrast').checked = false
    })
  }

  offerElementActivities = (elementId, activities) => {
    this.popover.find('button').remove()
    if (!config.userAuthenticated) {
      this.popover.append(this._createUserRegisterLoginButtons())
    } else {
      // render element specific activity buttons first, then generic ones
      Object.keys(activities).forEach(activityId => {
        if (activities[activityId].isElementSpecific)
          this.popover.append(
            this._createActivityButton(activities[activityId], elementId),
          )
      })
      Object.keys(activities).forEach(activityId => {
        if (!activities[activityId].isElementSpecific)
          this.popover.append(
            this._createActivityButton(activities[activityId], elementId),
          )
      })
    }
    const selectedElement = $(this.elements[elementId])
    selectedElement.popover({
      html: true,
      trigger: 'focus',
      title: 'What would you like to do?',
      content: this.popover,
    })
    selectedElement.popover('show')
  }

  offerSubmissionActivities = (submission, activities) => {
    this.popover.find('button').remove()
    Object.keys(activities).forEach(activityId => {
      this.popover.append(
        this._createActivityButton(activities[activityId], submission['id']),
      )
    })
    this.popover.append(
      $('<button/>', {
        type: 'button',
        class: 'share-submission btn btn-primary',
        text: submission['shared'] ? 'Unshare' : 'Share',
        'data-id': submission['id'],
      }),
    )
    const selectedSubmission = this.findSubmissionItem(submission['id'])
    selectedSubmission.popover({
      html: true,
      trigger: 'focus',
      title: 'What would you like to do?',
      content: this.popover,
    })
    selectedSubmission.popover('show')
  }

  highlightElementsWithCustomActivity = elementIds => {
    this.highlightElements(elementIds, 'mark custom-activity')
  }

  highlightElementsWithInteractivity = elementIds => {
    this.highlightElements(elementIds, 'mark')
  }

  highlightElements = (elementIds, cls) => {
    for (let i = 0; i < elementIds.length; i++) {
      this.highlightElement(elementIds[i], cls)
    }
  }

  highlightElement = (elementId, cls) => {
    $(this.elements[elementId]).addClass(cls || 'mark')
  }

  _positionModalInViewport() {
    var top, left
    // top = window.scrollY || window.pageYOffset
    // left = window.scrollX || window.pageXOffset
    // if (left && top) {
    //   this.modal['divDialog'][0].style.top = `${top}px`
    //   this.modal['divDialog'][0].style.left = `${left}px`
    // }
    // TODO: This is possibly not supported in old browsers
    if (window.visualViewport) {
      top = window.visualViewport.offsetTop
      left = window.visualViewport.offsetLeft
    }
    if (left && top) {
      this.modal['divModal'][0].style.top = `${top}px`
      this.modal['divModal'][0].style.left = `${left}px`
    }
  }

  findSubmissionItem(submissionId) {
    return this.modal['divBody'].find("li[data-id='" + submissionId + "']")
  }

  highlightSubmission = submissionId =>
    this.findSubmissionItem(submissionId).addClass('mark')

  bindSelectActivity = handler => {
    this.popover.on('click', 'button:not(.share-submission)', event => {
      handler(event.target.dataset['id'])
    })
  }

  bindShareSubmission = handler => {
    this.popover.on('click', 'button.share-submission', event => {
      handler(event.target.dataset['id'])
    })
  }

  bindSubmit = handler => {
    this.modal['divContent'].on('click', 'button.save-submission', event => {
      handler(this.modal['txtResponse'].val())
    })
  }

  bindElementClicked = handler => {
    $('div#page-text').on('click', 'span', event => {
      handler(event.target.dataset['id'])
    })
  }

  bindSubmissionClicked = handler => {
    this.modal['divBody'].on('click', 'li', event => {
      const submissionId =
        event.target.dataset['id'] || event.target.parentElement.dataset['id']
      handler(parseInt(submissionId))
    })
  }

  elicitStepSubmission = (activity, submissionId, message, newSubmission) => {
    // newSubmission passed iff this is a next step after a just-submitted step submission
    // If this gets much more complex, pass this elementId and
    // make it call `elicitElementSubmission` to render modal afresh first
    this.modal['divBody'].find('p').remove('.message')
    const li = $(this.findSubmissionItem(submissionId))
    const nextStep = activity.nextStep(submissionId)
    if (nextStep) {
      const bodyContents = []
      bodyContents.push(
        $('<p/>', {
          text: nextStep['title'],
          class: 'step-title  text-primary',
        }),
      )
      if (nextStep['instructions']) {
        bodyContents.push(
          $('<p/>', {
            text: nextStep['instructions'],
            class: 'step-instructions',
          }),
        )
      }
      this.modal['txtResponse'].val('')
      this.modal['txtResponse'].show()
      this.modal['btnSave'].show()
      bodyContents.push(this.modal['txtResponse'])
      bodyContents.push(this.modal['btnSave'])
      const div = $('<div/>').append(bodyContents)
      div.insertAfter(li)
      // don't focus, if phone landscape and keyboard up will scroll text out of view
      // this.modal['txtResponse'].trigger('focus')
    } else {
      this.modal['txtResponse'].hide()
      this.modal['btnSave'].hide()
      this.modal['divBody']
        .find('p')
        .remove('.step-title, .step-instructions, .message')
      $('<p/>', {
        text:
          message ||
          `You have finished ${activity['title']} on this submission`,
        class: 'message text-success',
      }).insertAfter(li)
      if (newSubmission) {
        $('<ul/>')
          .append(
            // issue - we don't have the latest submission, nor responseToId to get it from activity
            this._createSubmissionHistoryItem(newSubmission),
          )
          .insertAfter(li)
      }
    }
  }

  elicitElementSubmission = (activity, elementId, message) => {
    this.modal['divBody']
      .find('p')
      .remove(
        '.activity-introduction, .step-title, .step-instructions, .message',
      )
    this.modal['divBody'].find('ul').remove('.step-submission-history')

    const bodyContents = []
    if (activity['introduction']) {
      bodyContents.push(
        $('<p/>', {
          text: activity['introduction'],
          class: 'activity-introduction text-secondary',
        }),
      )
    }

    // render submissions to date
    if (activity.submissions[elementId]) {
      bodyContents.push(
        this._renderSubmissionHistory(activity.submissions[elementId]),
      )
    }

    if (message) {
      bodyContents.push(
        $('<p/>', {
          text: message,
          class: 'message text-success',
        }),
      )
    }

    // if there's more steps or the user is just starting this activity, render the instructions, textbox and save button, else just close btn
    if (activity.completed(elementId)) {
      this.modal['txtResponse'].hide()
      this.modal['btnSave'].hide()
      bodyContents.push(
        $('<p/>', {
          text: `You have finished "${activity['title']}" on this element`,
          class: 'message text-success',
        }),
      )
    } else {
      const nextStep = activity.nextStep(elementId)
      bodyContents.push(
        $('<p/>', {
          text: nextStep['title'],
          class: 'step-title text-primary',
        }),
      )
      bodyContents.push(
        $('<p/>', {
          text: nextStep['instructions'],
          class: 'step-instructions text-secondary',
        }),
      )
      this.modal['divFormGroup'].prepend(this.modal['txtResponse'])
      this.modal['txtResponse'].val('')
      this.modal['txtResponse'].show()
      this.modal['btnSave'].insertAfter(this.modal['btnClose'])
      this.modal['btnSave'].show()
    }
    this.modal['divBody'].prepend(bodyContents)
    this.modal['h5Title'].text(activity.title)
    this.modal['divModal'].modal({ focus: false })
    this._positionModalInViewport()
    // don't focus, if phone landscape and keyboard up will scroll text out of view
    // this.modal['txtResponse'].trigger('focus')
  }

  _renderSubmissionHistory(subs, depth) {
    if (subs) {
      depth = depth || 0
      let historyListItems = []
      // TODO: submissions will be a dict of lists, keyed on threadId:
      // Object.keys(subs).forEach(threadId=>{for (let i = 0; i < subs[threadId].length; i++) ...subs[threadId][i]...})
      for (let i = 0; i < subs.length; i++) {
        historyListItems.push(this._createSubmissionHistoryItem(subs[i]))
        if (subs[i]['submissions']) {
          historyListItems.push(
            this._renderSubmissionHistory(subs[i]['submissions'], depth + 1),
          )
        }
      }
      return $('<ul/>', { class: 'step-submission-history' }).append(
        historyListItems,
      )
    }
  }

  _createSubmissionHistoryItem = submission => {
    return $('<li/>', {
      'data-id': submission['id'],
    })
      .prepend([
        $('<b/>', { text: submission['name'] + ': ' }),
        $('<i/>', { text: submission['step']['title'] }),
        $('<span/>', { text: ': ' + submission['response'] }),
      ])
      .append('<span style="float:right;">&#8801;</span>')
  }

  _createActivityButton = (activity, elementId) => {
    return $('<button/>', {
      type: 'button',
      class: activity.started(elementId)
        ? 'btn btn-primary'
        : activity.isElementSpecific
        ? 'btn btn-success'
        : 'btn btn-secondary',
      text: activity.title,
      'data-id': activity.id,
    })
  }

  _createUserRegisterLoginButtons = () => {
    return [
      $('<button/>', {
        type: 'button',
        class: 'btn btn-primary',
        text: 'Log In',
        onclick: `window.location.href='${config.urlUserLogin}?next=${
          window.location.pathname
        }${window.location.search}'`,
      }),
      $('<button/>', {
        type: 'button',
        class: 'btn btn-secondary',
        text: 'Get An Account!',
        onclick: `window.location.href='${config.urlUserRegister}'`,
      }),
    ]
  }

  _createTextLayer = els => {
    const elements = {}
    Object.keys(els).forEach(elementId => {
      const domEl = this._createElement(els[elementId], elementId)
      this.textLayer.append(domEl)
      elements[elementId] = domEl
    })
    return elements
  }

  _createElement(elementData, elementId) {
    const el = document.createElement(elementData['name'])
    el.textContent = elementData['head']
    el.dataset.id = elementId
    el.style.cssText = elementData['style']
    return el
  }

  _createPopover = () => {
    return $(document.createElement('form'))
  }

  _createModal = () => {
    const divModal = document.createElement('div')
    divModal.tabIndex = '-1'
    divModal.className = 'modal fade'
    const divDialog = document.createElement('div')
    divDialog.className = 'modal-dialog'
    divModal.appendChild(divDialog)
    const divContent = document.createElement('div')
    divContent.className = 'modal-content'
    divDialog.appendChild(divContent)
    const divHeader = document.createElement('div')
    divHeader.className = 'modal-header'
    divContent.appendChild(divHeader)
    const h5Title = document.createElement('h5')
    h5Title.className = 'modal-title'
    divHeader.appendChild(h5Title)
    const btnHeaderClose = document.createElement('button')
    btnHeaderClose.className = 'close'
    // btnHeaderClose.innerHTML = '&times;' bootstrap docs say use inner span, not sure why
    btnHeaderClose.dataset.dismiss = 'modal'
    divHeader.appendChild(btnHeaderClose)
    const spanClose = document.createElement('span')
    spanClose.innerHTML = '&times;'
    btnHeaderClose.appendChild(spanClose)
    const divBody = document.createElement('div')
    divBody.className = 'modal-body'
    divContent.appendChild(divBody)
    const form = document.createElement('form')
    divBody.appendChild(form)
    const divFormGroup = document.createElement('div')
    form.appendChild(divFormGroup)
    const txtResponse = document.createElement('textarea') // TODO: custom widget/s, poss sent as Django-rendered HTML from API
    txtResponse.className = 'form-control submission-response'
    divFormGroup.appendChild(txtResponse)
    const divFooter = document.createElement('div')
    divFooter.className = 'modal-footer'
    divContent.appendChild(divFooter)
    const btnClose = document.createElement('button')
    btnClose.className = 'btn btn-secondary'
    btnClose.dataset.dismiss = 'modal'
    btnClose.textContent = 'Close'
    divFooter.appendChild(btnClose)
    const btnSave = document.createElement('button')
    btnSave.className = 'btn btn-primary save-submission' // TODO: automate periodic saving to server/localstore too
    btnSave.textContent = 'Save'
    divFooter.appendChild(btnSave)

    return {
      divModal: $(divModal),
      divDialog: $(divDialog),
      divContent: $(divContent),
      divBody: $(divBody),
      divFormGroup: $(divFormGroup),
      h5Title: $(h5Title),
      form: $(form),
      txtResponse: $(txtResponse),
      btnClose: $(btnClose),
      btnSave: $(btnSave),
    }
  }
}
