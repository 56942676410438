import { View } from './view'
import { ActivityStore, ElementStore } from './store'
import { Api } from './api'

export class Controller {
  constructor() {
    this.state = {
      elementId: 0,
      submissionId: 0,
      activityId: 0,
      userInput: '',
      activityStore: {},
      elementStore: {},
    }

    this.api = new Api(config)
    this.api.requestElements(this.handleElementsLoaded, config.pageId)
    this.api.requestActivities(this.handleActivitiesLoaded, config.pageId)
  }

  _constructNewSubmission = (submissionId, nextStepId, sequence, message) => {
    const activity = this._getCurrentActivity()
    const itemId = this.state['elementId'] || this.state['submissionId']
    const newSubmission = {
      id: submissionId,
      response: this.state['userInput'],
      next_step_id: nextStepId,
      sequence,
      name: config.name,
      message,
      step_id: activity.nextStepId(itemId),
      step: this.activityStore.getStep(activity.nextStepId(itemId)),
    }
    if (this.state['elementId']) {
      newSubmission['element_id'] = itemId
    } else {
      newSubmission['response_to_id'] = itemId
    }
    return newSubmission
  }

  handleShareSubmission = (submissionId, shared) => {
    this.state['submissionId'] = submissionId
    if (submissionId) {
      this.api.toggleShareSubmission(
        this.handleShareSubmissionCompleted,
        submissionId,
      )
    }
  }

  handleShareSubmissionCompleted = response => {
    const { submissionId, shared } = response
    this.activityStore.getSubmission(submissionId)['shared'] = shared
  }

  handleSubmitCompleted = response => {
    const { submissionId, nextStepId, sequence, message } = response
    const newSubmission = this._constructNewSubmission(
      submissionId,
      nextStepId,
      sequence,
    )
    this.activityStore.appendSubmission(
      this._getCurrentActivity(),
      newSubmission,
    )
    if (this.state['elementId']) {
      this.view.elicitElementSubmission(
        this._getCurrentActivity(),
        this.state['elementId'],
        message || 'Saved',
      )
    } else {
      this.view.elicitStepSubmission(
        this._getCurrentActivity(),
        this.state['submissionId'],
        message || 'Saved',
        newSubmission,
      )
    }
  }

  handleActivitiesLoaded = activityData => {
    this.activityStore = new ActivityStore(activityData)
    if (this.elementStore) {
      this.handleElementsAndActivitiesLoaded()
    }
  }

  handleElementsLoaded = elementData => {
    this.elementStore = new ElementStore(elementData)
    this.view = new View(this.elementStore.getElements())
    this.view.bindSelectActivity(this.handleActivityClicked)
    this.view.bindSubmit(this.handleSubmit)
    this.view.bindShareSubmission(this.handleShareSubmission)
    this.view.bindElementClicked(this.handleElementClicked)
    if (this.activityStore) {
      this.handleElementsAndActivitiesLoaded()
    }
  }

  handleElementsAndActivitiesLoaded = () => {
    this.view.bindSubmissionClicked(this.handleSubmissionClicked)
    const {
      elementIdsWithCustomActivity,
      elementIdsWithInteraction,
    } = this.activityStore.getElementsWithInteractions()
    this.view.highlightElementsWithInteractivity(elementIdsWithInteraction)
    this.view.highlightElementsWithCustomActivity(elementIdsWithCustomActivity)
  }

  handleElementClicked = elementId => {
    console.log(`El ${elementId}`)
    this.state['submissionId'] = 0
    this.state['activityId'] = 0
    this.state['elementId'] = elementId
    const activities = this.activityStore.getElementActivities(elementId)
    this.view.highlightElement(elementId)
    this.view.offerElementActivities(elementId, activities)
  }

  handleSubmissionClicked = submissionId => {
    this.state['submissionId'] = submissionId
    const sub = this.activityStore.getSubmission(submissionId)
    const activities = this.activityStore.getStepActivities(sub['step_id'])
    this.view.highlightSubmission(submissionId)
    this.view.offerSubmissionActivities(sub, activities)
  }

  handleActivityClicked = activityId => {
    this.state['activityId'] = activityId
    if (this.state['submissionId']) {
      this.state['elementId'] = 0
      this.view.elicitStepSubmission(
        this._getCurrentActivity(),
        this.state['submissionId'],
      )
    } else {
      this.view.elicitElementSubmission(
        this._getCurrentActivity(),
        this.state['elementId'],
      )
    }
  }

  handleSubmit = userInput => {
    if (userInput) {
      this.state['userInput'] = userInput
      this.api.submit(
        this.handleSubmitCompleted,
        this._getCurrentActivity(),
        this.state['elementId'] || this.state['submissionId'],
        userInput,
      )
    }
  }

  _getCurrentActivity = () => {
    if (this.state['submissionId']) {
      const stepId = this.activityStore.getSubmission(
        this.state['submissionId'],
      )['step_id']
      return this.activityStore.getActivityForStep(
        this.state['activityId'],
        stepId,
      )
    } else {
      return this.activityStore.getActivityForElement(
        this.state['activityId'],
        this.state['elementId'],
      )
    }
  }
}
