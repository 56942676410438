export class ActivityStore {
  constructor(responseData) {
    this.specificElementActivities =
      responseData['element_specific_activities'] || {}
    this.allElementActivities = responseData['all_element_activities'] || {}
    const elSubs = responseData['element_submissions'] || {}
    this.specificStepActivities = responseData['step_specific_activities'] || {}
    this.allStepActivities = responseData['all_step_activities'] || {}
    const stepSubs = responseData['step_submissions'] || {}

    // parse all activities into Activity instances (in place)
    this._runOnElAndStepActivities(this._castActivities, elSubs, stepSubs)

    // attach step activities to steps
    this._runOnElAndStepActivities(this._attachStepActivities)

    // attach step submissions to their parent submissions
    // create index on stepSubmissions keyed by the submission they're responding to
    const stepSubmissionsByParentSubId = this._stepSubmissionsByResponseToSubId(
      stepSubs,
    )
    this._attachStepSubmissionsToParentSubmissions(
      elSubs,
      stepSubmissionsByParentSubId,
    )
    this._attachStepSubmissionsToParentSubmissions(
      stepSubs,
      stepSubmissionsByParentSubId,
    )

    this.submissions = {}
    this._extractSubmissions(elSubs, this.submissions)
    this._extractSubmissions(stepSubs, this.submissions)

    // attach steps back to their submissions
    // build index of all steps
    this.steps = this._extractAllSteps()
    this._attachStepsToSubmissions(elSubs)
    this._attachStepsToSubmissions(stepSubs)
  }

  _attachStepsToSubmissions(subs) {
    Object.keys(subs).forEach(activityId => {
      Object.keys(subs[activityId]).forEach(itemId => {
        for (let i = 0; i < subs[activityId][itemId].length; i++) {
          const sub = subs[activityId][itemId][i]
          sub['step'] = this.steps[sub['step_id']]
        }
      })
    })
  }

  getStep = stepId => this.steps[stepId]

  getSubmission = submissionId => this.submissions[submissionId]

  _extractSubmissions(itemSubs, subs) {
    Object.keys(itemSubs).forEach(activityId => {
      Object.keys(itemSubs[activityId]).forEach(itemId => {
        for (let i = 0; i < itemSubs[activityId][itemId].length; i++) {
          const sub = itemSubs[activityId][itemId][i]
          subs[sub['id']] = sub
        }
      })
    })
  }

  _extractAllSteps() {
    const steps = {}
    Object.keys(this.specificElementActivities).forEach(elementId => {
      this._extractSteps(this.specificElementActivities[elementId], steps)
    })
    this._extractSteps(this.allElementActivities, steps)
    Object.keys(this.specificStepActivities).forEach(stepId => {
      this._extractSteps(this.specificStepActivities[stepId], steps)
    })
    this._extractSteps(this.allStepActivities, steps)
    return steps
  }

  _extractSteps(activities, steps) {
    Object.keys(activities).forEach(activityId => {
      Object.assign(steps, activities[activityId]['steps'])
    })
  }

  _runOnElAndStepActivities = (callable, elementParameter, stepParameter) => {
    this._runOnAllActivities(
      callable,
      this.specificElementActivities,
      this.allElementActivities,
      elementParameter,
    )
    this._runOnAllActivities(
      callable,
      this.specificStepActivities,
      this.allStepActivities,
      stepParameter,
    )
  }

  _stepSubmissionsByResponseToSubId = stepSubs => {
    // stepSubmissions come from API as {activityId: {responseToStepId: [{id, step_id, response, etc}, .. ]}}
    const stepSubmissionsByResponseToSubId = {}
    Object.keys(stepSubs).forEach(activityId => {
      Object.assign(stepSubmissionsByResponseToSubId, stepSubs[activityId])
    })
    return stepSubmissionsByResponseToSubId
  }

  _attachStepSubmissionsToParentSubmissions = (
    parentSubmissions,
    stepSubmissionsByParentSubId,
  ) => {
    Object.keys(parentSubmissions).forEach(activityId => {
      Object.keys(parentSubmissions[activityId]).forEach(itemId => {
        const subs = parentSubmissions[activityId][itemId]
        for (let i = 0; i < subs.length; i++) {
          const responses = stepSubmissionsByParentSubId[subs[i]['id']]
          subs[i]['submissions'] = responses || [] // array of submissions to this submission in sequence order
        }
      })
    })
  }

  _runOnAllActivities = (
    callable,
    itemSpecificActivities,
    globalActivities,
    params,
  ) => {
    callable(globalActivities, params, false)
    Object.keys(itemSpecificActivities).forEach(elementId => {
      callable(itemSpecificActivities[elementId], params, true)
    })
  }

  _attachStepActivities = activities => {
    Object.keys(activities).forEach(activityId => {
      const activity = activities[activityId]
      activity._attachStepActivities(this.specificStepActivities) // {parentStepId: {activityId: {activity}}}
    })
  }

  _castActivityLists = (
    itemSpecificActivities,
    genericActivities,
    submissions,
  ) => {
    this._runOnAllActivities(
      this._castActivities,
      itemSpecificActivities,
      genericActivities,
      submissions,
    )
  }

  _castActivities = (activities, submissions, isElementSpecific) => {
    Object.keys(activities).forEach(activityId => {
      activities[activityId] = new Activity(
        activities[activityId],
        submissions[activityId],
        isElementSpecific,
      )
    })
  }

  getElementsWithInteractions = () => {
    let elsWithInteraction = []
    Object.keys(this.allElementActivities).forEach(activityId => {
      elsWithInteraction = elsWithInteraction.concat(
        this.allElementActivities[activityId].itemsStarted(),
      )
    })
    return {
      elementIdsWithCustomActivity: Object.keys(this.specificElementActivities),
      elementIdsWithInteraction: elsWithInteraction,
    }
  }

  getElementActivities = elementId => {
    return Object.assign(
      {},
      this.allElementActivities,
      this.specificElementActivities[elementId],
    )
  }

  getStepActivities = stepId => {
    return Object.assign(
      {},
      this.allStepActivities,
      this.specificStepActivities[stepId],
    )
  }

  getActivityForElement = (activityId, elementId) => {
    return (
      this.allElementActivities[activityId] ||
      this.specificElementActivities[elementId][activityId]
    )
  }

  getActivityForStep = (activityId, stepId) => {
    return (
      this.allStepActivities[activityId] ||
      this.specificStepActivities[stepId][activityId]
    )
  }

  appendSubmission = (activity, submission) => {
    // submission['step'] = this.steps[submission['step_id']] this is done in controller constructor
    activity.appendSubmission(submission)
    this.submissions[submission['id']] = submission
    if (submission['response_to_id']) {
      const parentSub = this.submissions[submission['response_to_id']]
      if (!parentSub['submissions']) parentSub['submissions'] = []
      parentSub['submissions'].push(submission)
    }
  }
}

class Activity {
  constructor(activityDict, submissionDict, isElementSpecific) {
    this.isElementSpecific = isElementSpecific
    this.id = activityDict['id']
    this.firstStepId = activityDict['first_step_id']
    this.title = activityDict['title']
    this.introduction = activityDict['introduction']
    this.steps = activityDict['steps'] // {stepId: {id, instructions, title}}
    this.submissions = submissionDict || {} // {element/responseToStepId: [{id, step_id, response, next_id, sequence, date_submitted, name}]}
  }

  _attachStepActivities = specificStepActivitiesByParentStepId => {
    Object.keys(this.steps).forEach(stepId => {
      this.steps[stepId]['activities'] =
        specificStepActivitiesByParentStepId[stepId]
    })
  }

  completed = itemId => {
    // true if there are submissions and the last one doesn't have a next_id
    return (
      this.submissions[itemId] &&
      !this.submissions[itemId][this.submissions[itemId].length - 1][
        'next_step_id'
      ]
    )
  }

  nextStepId = itemId => {
    if (this.completed(itemId)) return false
    return this.submissions[itemId]
      ? this.submissions[itemId][this.submissions[itemId].length - 1][
          'next_step_id'
        ]
      : this.firstStepId
  }

  nextStep = itemId => {
    return this.steps[this.nextStepId(itemId)]
  }

  started = itemId => {
    return !!this.submissions[itemId]
  }

  itemsStarted = () => {
    const els = []
    Object.keys(this.submissions).forEach(itemId => {
      if (this.started(itemId)) els.push(itemId)
    })
    return els
  }

  appendSubmission = submission => {
    const itemId = parseInt(
      submission['element_id'] || submission['response_to_id'],
    )
    if (!this.submissions[itemId]) this.submissions[itemId] = []
    this.submissions[itemId].push(submission)
  }
}

export class ElementStore {
  constructor(responseData) {
    this.elements = {}
    for (let i = 0; i < responseData.length; i++) {
      this.elements[responseData[i]['id']] = responseData[i]
      delete responseData[i]['id']
    }
  }

  getElements = () => this.elements
}
